import { fetch } from 'dva';
import { message } from 'antd';
import { stringify } from 'qs';
import { captureException } from '@sentry/react';

import intl from 'react-intl-universal';

// import store from 'src/index';
import { getApiUrl } from 'utils/utils';
import { getQueryUrlParams } from 'utils/global';
import getUserInfo, { getStoreId } from 'utils/getUserInfo';
import {
  setAccessPass as setAuthority,
  getAccessPass,
  removeAccessPass,
  getAgencyUserKey
} from './utils';

const getAuthorityHeader = () => {
  return {
    Authorization: getAccessPass()
  };
};

const logOut = () => {
  removeAccessPass();
  // 如果已经在agency管理页面就直接刷新
  if (location.pathname.includes('/app/agency-cost-management')) {
    window.location.reload();
    // 否则直接进入agency管理页
  } else {
    const key = getAgencyUserKey();
    window.location.href = `${location.origin}/app/agency-cost-management?key=${key}`;
  }
};

export const codeMessage = {
  // 200: "The server successfully returned the requested data.",
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'Delete data successfully.',
  400: 'The request was issued with an error and the server did not perform a new or modified data operation.',
  401: 'The user does not have permission (token, username, password error).',
  403: 'The user is authorized but the access is forbidden.',
  404: 'The requested request is for a record that does not exist and the server did not operate.',
  406: 'The requested format is not available.',
  410: 'The requested resource is permanently deleted and will not be regained.',
  422: 'A validation error occurred while creating an object.',
  500: 'The server has encountered an error. Please check the server.',
  502: 'The gateway is wrong.',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained.',
  504: 'Gateway times out.'
};
// 隐藏错误提示信息
const ignoreErrorTip = [
  'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.',
  'The quantity of supplier has reached limit',
  'refresh token'
];
export function errFn(status, option) {
  if (status >= 500) {
    const data = { code: 0, data: '', msg: 'Server error' };
    if (option) {
      if (option.callback) {
        try {
          option.callback(data);
        } catch (error) {
          console.error('callback 出错了:', error);
          captureException(error);
          throw new Error(error);
        }
      }
    }
    message.error(data.msg);
    return data;
  }
}

function checkStatus(response, options = {}, url, newEnv) {
  const msg = options.message || {};
  if (response.status === 200) {
    return response.json().then(resData => {
      if (resData.code === 5000 && response.url.includes('/api/v1/ad/auto')) {
        return resData;
      }

      if (resData.code === 205 && resData.token) {
        setAuthority(resData.token);
        return request(url, newEnv, options);
      }

      if (resData.code === 206 && resData.token) {
        setAuthority(resData.token);
      }

      if (options.callback) {
        try {
          options.callback(resData);
        } catch (error) {
          captureException(error);
          console.error('callback 出错了:', error);
          throw new Error(error);
        }
      }
      if (options.cancelMessage) {
        return resData;
      }
      if (resData.code === 2000 || resData.code === 2010) {
        const successText = msg.success;
        if (successText) {
          message.success(successText);
        }
      } else {
        const errortext =
          resData.msg || msg.error || codeMessage[response.status];

        if (errortext) {
          if (errortext == 'split product err') {
            message.error(intl.get('product_split.select_split_mothod'));
            return;
          }

          if (
            errortext == 'The supplier has been mapped and cannot be deleted.'
          ) {
            message.error(intl.get('setting.agency.delError'));
            return;
          }

          if (errortext == 'The product you entered was not found') {
            message.error(intl.get('product_split.product_not_found'));
            return;
          }
          if (
            errortext ==
            'The operation failed due to network issue, please try again'
          ) {
            message.error(intl.get('product_split.network_issue'));
          }
          if (errortext == 'repeated info') {
            message.error(intl.get('setting.order.order_same'));
            return;
          }
          if (
            errortext ===
            'The store is disconnected, please go to Setting - Account to reconnect it'
          ) {
            message.error(intl.get('setting.priceUpdate.priceError'));
            return;
          }
          if (errortext == 'This product had been deleted in DSers') {
            message.error(intl.get('setting.order.delete_dsers'));
            return;
          }
          if (!ignoreErrorTip.includes(errortext)) {
            message.error(errortext);
            // 收集服务器抛出的错误信息 - 需求：整理SP平台错误信息
            // TODO: 预计22/07/14上线，2-3周后视收集情况决定是否下线此打点
            if (
              url &&
              url.includes &&
              !url.includes('/collect/') &&
              typeof window.DSERS_CUSTOM_COLLECT === 'function'
            ) {
              DSERS_CUSTOM_COLLECT({
                action: 'API_SERVICE_ERROR_MSG_REPORT',
                custom_info: [
                  { name: 'API', value: url },
                  { name: 'ERROR_MSG', value: errortext },
                  { name: 'ERROR_CODE', value: `${resData?.code || 0}` }
                ]
              });
            }
          }
        }
      }
      return resData;
    });
  }
  if (response.status >= 500) {
    if (response.url.includes('mgnoberlo-core-api')) {
      if (window.location.pathname !== '/app/error-migrate') {
        window.location.href = '/app/error-migrate?code=500';
      }
    } else {
      return errFn(response.status, options);
    }
  }
  if (response.status == 426) {
    if (window.location.pathname !== '/app/error-migrate') {
      window.location.href = '/app/error-migrate?code=426';
    }
  }
  if (response.status == 428) {
    if (window.location.pathname !== '/app/data-migrate') {
      window.location.href = '/app/data-migrate';
    }
  }
  const error = new Error();
  error.name = response.status;
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url = '', newEnv, options = {}, tmallNew) {
  const defaultOptions = {
    credentials: 'include'
  };
  const newOptions = { ...defaultOptions, ...options };

  let storeId =
    localStorage.getItem('pushCheckStoreId') || localStorage.getItem('storeId');

  if (window.location.search && !window.ISFLAG) {
    let storeIdVal = getQueryUrlParams(window.location.href, 'storeId');
    storeId = storeIdVal;
  }

  // console.log('request storeId为', storeId)

  newOptions.headers = {
    ...getAuthorityHeader(),
    DSID: storeId,
    ...options.customHeaders
  };

  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
        ...newOptions.headers,
        ...options.customHeaders
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      let storeId = localStorage.getItem('storeId');
      if (window.location.search && !localStorage.getItem('selectStore')) {
        let storeIdVal = getQueryUrlParams(window.location.href, 'storeId');
        storeId = storeIdVal;
      }

      newOptions.headers = {
        ...getAuthorityHeader(),
        DSID: storeId,
        ...options.customHeaders,
        'Cache-Control': 'no-cache'
      };
    }
  }
  const apiUrl = !options.isCustomApi
    ? getApiUrl(url, !!options.isOutApi, newEnv)
    : url;
  if (apiUrl.includes('/migration/store/status')) {
    return {
      store_stages: [],
      last_pre_complete_timestamp: '0',
      last_ae_not_available: 0,
      is_last_step2_order_completed: false
    };
  }
  if (apiUrl.includes('/migration/user/status')) {
    return {
      is_migrating: false,
      status: 'MIGRATION_STATUS_',
      item_steps: [],
      migrating_shopify_domain: '',
      step_stage: 'STEP_STAGE_',
      is_new_user: false,
      is_new_store: false,
      is_bind_ae: false,
      err_code: '',
      err_msg: '',
      dsers_store_id: '',
      tips: [],
      tag: '',
      is_step2_order_completed: true
    };
  }
  return fetch(apiUrl, newOptions)
    .then(response => {
      return checkStatus(response, options || {}, url, newEnv);
    })
    .catch(e => {
      const status = e.name;

      if (status === 401) {
        logOut();
      }

      if (status <= 504 && status >= 500) {
        const res = errFn(status, options);
        return res || e;
      }

      if (options.exceptionFn) {
        options.exceptionFn(e);
      }

      const msg = options.message || {};
      if (msg.error) {
        message.error(msg.error);
      }
      return e;
    });
}

export function Post(url, payload = {}, newEnv, tmallNew) {
  const userInfo = getUserInfo();
  const { data = {}, headers = {} } = payload;
  if (userInfo && userInfo.secondLevelId) {
    data.ower_bussiness_account_id = userInfo.secondLevelId;
  }

  return request(url, newEnv, {
    method: 'POST',
    body: data,
    message: payload.message,
    callback: payload.callback,
    exceptionFn: payload.exceptionFn,
    isOutApi: !!payload.isOutApi,
    isCustomApi: !!payload.isCustomApi,
    cancelMessage: !!payload.cancelMessage,
    customHeaders: headers
  });
}

export function Put(url, payload = {}, newEnv) {
  const userInfo = getUserInfo();
  const { data = {} } = payload;
  if (userInfo && userInfo.secondLevelId) {
    data.ower_bussiness_account_id = userInfo.secondLevelId;
  }

  return request(url, newEnv, {
    method: 'PUT',
    body: data,
    message: payload.message,
    callback: payload.callback,
    isOutApi: !!payload.isOutApi,
    isCustomApi: !!payload.isCustomApi,
    cancelMessage: !!payload.cancelMessage
  });
}

export function Delete(url, payload = {}, newEnv) {
  const { data = {}, headers = {} } = payload;
  // const path = payload.data
  //   ? `${url}?${stringify(payload.data, {
  //       encode: false,
  //       arrayFormat: 'comma'
  //     })}`
  //   : url;

  return request(url, newEnv, {
    method: 'DELETE',
    body: data,
    message: payload.message,
    callback: payload.callback,
    exceptionFn: payload.exceptionFn,
    isOutApi: !!payload.isOutApi,
    isCustomApi: !!payload.isCustomApi,
    cancelMessage: !!payload.cancelMessage,
    customHeaders: headers
  });
}

export function Get(url, payload = {}, newEnv, tmallNew) {
  const path = payload.data
    ? `${url}?${stringify(payload.data, {
        encode: false,
        arrayFormat: 'comma'
      })}`
    : url;

  return request(path, newEnv, {
    method: 'GET',
    message: payload.message,
    callback: payload.callback,
    exceptionFn: payload.exceptionFn,
    isOutApi: !!payload.isOutApi,
    isCustomApi: !!payload.isCustomApi,
    cancelMessage: !!payload.cancelMessage
  });
}

export function syncGet(url, payload = {}) {
  return new Promise((reslove, reject) => {
    const ajax = new XMLHttpRequest();
    const path = payload.data
      ? `${url}?${stringify(payload.data, {
          encode: false,
          arrayFormat: 'comma'
        })}`
      : url;
    const newUrl = getApiUrl(path, !!payload.isOutApi);

    ajax.addEventListener('loadend', event => {
      reslove(JSON.parse(event.target.responseText));
    });
    ajax.addEventListener('error', event => {
      reject(event);
    });

    ajax.open('GET', newUrl, false);
    ajax.setRequestHeader('Accept', 'application/json');
    ajax.setRequestHeader('Authorization', getAuthorityHeader().Authorization);
    ajax.send();
  })
    .then(data => {
      if (payload.callback) {
        try {
          payload.callback(data);
        } catch (error) {
          console.error('callback 出错了:', error);
          captureException(error);
          throw new Error(error);
        }
      }
      return data;
    })
    .catch(() => {
      const msg = payload.message || {};
      if (msg.error) {
        message.error(msg.error);
      }
    });
}
