import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'antd'

import styles from './IconButton.less';

// export class IconButton extends React.PureComponent {
//     constructor(props) {
//         super(props);
//     }
//     render() {
//         const { icon, className, children, ...restProps } = this.props;
//         const calculateClassNames = classNames(className, styles.dsersIconButton)
//         return <Button className={calculateClassNames} {...restProps} icon={icon && typeof icon !== 'string' ? undefined : icon}>
//             <span className={styles.buttonContent}>
//                 {icon && typeof icon !== 'string' ? <span className={styles.icon}>{icon}</span> : null}
//                 {children}
//             </span>
//         </Button>;
//     }
// }


export const IconButton = React.memo(({ icon, className, children, type, tinyPadding, onlyIcon, ...restProps }) => {
    const calculateClassNames = useMemo(() => {
        return classNames(className, styles.dsersIconButton, {[styles.tinyPadding]: tinyPadding});
    }, [className, tinyPadding]);
    // console.log(restProps, params2, '----components -----hahahahahahahaha------');
    return <Button className={calculateClassNames} type={type} {...restProps} icon={icon && typeof icon !== 'string' ? undefined : icon}>
        <span className={styles.buttonContent}>
            {icon && typeof icon !== 'string' ? <span className={classNames(styles.icon, { [styles.onlyIcon]: onlyIcon })}>{icon}</span> : null}
            {children}
        </span>
    </Button>;
})