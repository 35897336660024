import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import {
  Button,
  Spin,
  Drawer,
  Modal,
  InputNumber,
  Select,
  Space,
  Form,
  Input,
  Pagination,
  Tooltip
} from 'antd';

const InputGroup = Input.Group;

import AgencyManagementTable from './AgencyManagementTable';
import TextButton from './TextButton';
import styles from './Common.less';
import { IconButton } from 'routes/AgencyOrder/component/IconButton';
import { countryList } from './country';
import { Delete, Get, Post } from '../request';

@Form.create()
class EditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: JSON.parse(JSON.stringify(countryList)), // 国家列表
      coefficientMin: 0
    };
  }

  setCountry = e => {
    if (!e.length) {
      this.setState({
        options: JSON.parse(JSON.stringify(countryList))
      });
      return;
    }

    const { options } = this.state;
    if (e.includes('GLOBAL')) {
      options.forEach(item => {
        if (item.Code !== 'GLOBAL') {
          item.disable = true;
        } else {
          item.disable = false;
        }
      });
    } else {
      options.forEach(item => {
        if (item.Code === 'GLOBAL') {
          item.disable = true;
        } else {
          item.disable = false;
        }
      });
    }

    this.setState({
      options: JSON.parse(JSON.stringify(options))
    });
  };

  setOperationalSymbol = value => {
    console.log(value);
    if (value === 1) {
      this.setState({
        coefficientMin: 0
      });
    } else {
      this.setState({
        coefficientMin: 1.00
      });
    }
  };

  componentDidMount() {
    const { record } = this.props;


    this.setOperationalSymbol(record?.coefficient_type || 1);

    console.log(this.props.form);

    if (record?.send_goods_country?.length) {
      this.setCountry(record.send_goods_country);
    }
  }

  render() {
    const { options, coefficientMin } = this.state;

    console.log(coefficientMin);

    const { record, form, onChange } = this.props;
    const { getFieldDecorator, getFieldValue, validateFields } = form;
    const { time } = record;

    const initMinTime = time?.split('-')?.[0] || 3;
    const initMaxTime = time?.split('-')?.[1] || initMinTime;

    return (
      <Form name="agency-shipping-templete" onChange={onChange}>
        <div>
          <Form.Item label="物流方案名称" required>
            {getFieldDecorator('name', {
              initialValue: record.name,
              rules: [
                { message: '物流方案名称不能为空', required: true },
                {
                  validator: (rule, value, callback) => {
                    if (value && !value.trim()) {
                      callback(Promise.reject());
                      return;
                    }
                    callback();
                  },
                  message: '物流方案名称不能为空'
                }
              ]
            })(<Input placeholder="请输入物流方案名称" />)}
          </Form.Item>
        </div>
        <div>
          <Form.Item label="物流时效（单位：天）" required>
            <div className={styles.inputLine}>
              <div className={styles.inputLineItem}>
                <div className={styles.inputLineItemLabel}>最小天数</div>
                <Form.Item>
                  {getFieldDecorator('min_time', {
                    initialValue: initMinTime || 0,
                    rules: [
                      { message: '天数不能为空', required: true },
                      {
                        validator: (rule, value, callback) => {
                          const max = getFieldValue('max_time');
                          if ((parseInt(max) && max) || max === '0') {
                            if (parseInt(max) < parseInt(value)) {
                              callback(Promise.reject());
                              return;
                            }
                          }
                          callback();
                        },
                        message: '最小值不能大于最大值'
                      }
                    ]
                  })(
                    <InputNumber
                      placeholder="请输入最小天数"
                      min={0}
                      max={999}
                      step={1}
                      precision={0}
                    />
                  )}
                </Form.Item>
              </div>
              <div className={styles.inputLineItem}>
                <div className={styles.inputLineItemLabel}>最大天数</div>
                <Form.Item>
                  {getFieldDecorator('max_time', {
                    initialValue: initMaxTime || 80,
                    rules: [{ message: '天数不能为空', required: true }]
                  })(
                    <InputNumber
                      onBlur={() => validateFields(['min_time'])}
                      min={0}
                      max={999}
                      step={1}
                      precision={0}
                      placeholder="请输入最大天数"
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item label="是否可追踪" required>
            {getFieldDecorator('is_trace', {
              initialValue: record.is_trace === 1 ? 1 : 2
            })(
              <Select getPopupContainer={p => p.parentNode || document.body}>
                <Select.Option value={2}>是</Select.Option>
                <Select.Option value={1}>否</Select.Option>
              </Select>
            )}
          </Form.Item>
        </div>
        <div>
          <Form.Item label="送达国家" required>
            {getFieldDecorator('send_goods_country', {
              initialValue: record.send_goods_country || 'GLOBAL'
            })(
              <Select
                getPopupContainer={p => p.parentNode || document.body}
                showSearch
                onChange={this.setCountry}
                mode="multiple"
                optionFilterProp="label"
              >
                {options.map(i => {
                  return (
                    <Select.Option
                      key={i.Code}
                      label={i.Name}
                      value={i.Code}
                      disabled={i.disable}
                    >
                      {i.Name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        </div>
        <div>
          <Form.Item label="重量区间（单位：g）" required>
            <div className={styles.inputLine}>
              <div className={styles.inputLineItem}>
                <div className={styles.inputLineItemLabel}>最小值</div>
                <Form.Item>
                  {getFieldDecorator('weight_min', {
                    initialValue: record.weight_min || 0,
                    rules: [
                      { message: '最小值不能为空', required: true },
                      {
                        validator: (rule, value, callback) => {
                          const max = getFieldValue('weight_max');
                          if ((parseInt(max) && max) || max === '0') {
                            if (parseInt(max) < parseInt(value)) {
                              callback(Promise.reject());
                            }
                          }
                          callback();
                        },
                        message: '最小值不能大于最大值'
                      }
                    ]
                  })(
                    <InputNumber
                      min={0}
                      max={99999999}
                      step={1}
                      precision={0}
                      placeholder="请输入最小重量"
                    />
                  )}
                </Form.Item>
              </div>
              <div className={styles.inputLineItem}>
                <div className={styles.inputLineItemLabel}>最大值</div>
                <Form.Item>
                  {getFieldDecorator('weight_max', {
                    initialValue: record.weight_max || 0,
                    rules: [{ message: '最大值不能为空', required: true }]
                  })(
                    <InputNumber
                      min={0}
                      max={99999999}
                      step={1}
                      precision={0}
                      onBlur={() => validateFields(['weight_min'])}
                      placeholder="请输入最大重量"
                    />
                  )}
                </Form.Item>
              </div>
            </div>
          </Form.Item>
        </div>
        <div>
          <Form.Item required label="首重费用（单位：$/g）">
            {getFieldDecorator('first_weight_fee', {
              initialValue: record.first_weight_fee,
              rules: [{ message: '首重费用不能为空', required: true }]
            })(
              <InputNumber
                placeholder="请输入首重费用"
                min={0}
                max={99999999}
                precision={3}
                step={0.01}
              />
            )}
          </Form.Item>
        </div>
        <div>
          <Form.Item required label="续重费用（单位：$）">
            {getFieldDecorator('reweight_fee', {
              initialValue: record.reweight_fee,
              rules: [{ message: '续重费用不能为空', required: true }]
            })(
              <InputNumber
                placeholder="请输入续重费用"
                min={0}
                max={99999999}
                precision={3}
                step={0.01}
              />
            )}
          </Form.Item>
        </div>
        <div>
          <Form.Item required label="挂号费（单位：$）">
            {getFieldDecorator('registration_fee', {
              initialValue: record.registration_fee,
              rules: [{ message: '挂号费不能为空', required: true }]
            })(
              <InputNumber
                placeholder="请输入挂号费"
                min={0}
                max={99999999}
                precision={2}
                step={0.01}
              />
            )}
          </Form.Item>
        </div>
        <div>
          <Form.Item required label="首重（单位：g)">
            {getFieldDecorator('first_weight', {
              initialValue: record.first_weight,
              rules: [{ message: '首重不能为空', required: true }]
            })(
              <InputNumber
                placeholder="请输入首重"
                min={0}
                max={99999999}
                precision={0}
                step={1}
              />
            )}
          </Form.Item>
        </div>
        <div>
          <Form.Item required label="计费续重（单位：g)">
            {getFieldDecorator('reweight', {
              initialValue: record.reweight,
              rules: [{ message: '计费续重不能为空', required: true }]
            })(
              <InputNumber
                placeholder="请输入计费续重"
                min={1}
                max={99999999}
                precision={0}
                step={1}
              />
            )}
          </Form.Item>
        </div>

        <div>
          <Form.Item
            required
            label={
              <>
                系数{' '}
                <Tooltip
                  placement="top"
                  title="最终物流价格=【该系数】＋/×【当前物流模版配置的物流价格】"
                >
                  <div className={styles.outline}>
                    <i class="material-icons">help_outline</i>
                  </div>
                </Tooltip>
              </>
            }
          >
            <InputGroup compact>
              {getFieldDecorator('coefficient_type', {
                initialValue: record.coefficient_type || 1
              })(
                <Select
                  onChange={this.setOperationalSymbol}
                  style={{ width: 50 }}
                >
                  <Select.Option value={1}>+</Select.Option>
                  <Select.Option value={2}>x</Select.Option>
                </Select>
              )}

              {getFieldDecorator('coefficient', {
                initialValue: record.coefficient || coefficientMin
              })(
                <InputNumber
                  placeholder="请输入系数"
                  width={120}
                  precision={2}
                  min={coefficientMin}
                  max={99999999}
                  step={0.01}
                />
              )}
            </InputGroup>
          </Form.Item>
        </div>
        <div style={{ height: 80 }} />
      </Form>
    );
  }
}

const EidtDrawer = ({ record, visible, onClose, ...restProps }) => {
  const editedRef = useRef(false);
  const editedNotSavedRef = useRef(false);
  const formInstance = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const confirmClose = () => {
    onClose && onClose(editedRef.current);
    editedRef.current = false;
    editedNotSavedRef.current = false;
    setSubmitLoading(false);
  };

  const handleSave = async () => {
    setSubmitLoading(true);
    let result = false;
    try {
      // console.log(formInstance.current);
      const form = formInstance.current;
      // console.log(form);
      if (form) {
        const res = await form.validateFields();

        // 数组转字符串
        res.send_goods_country = res.send_goods_country?.toString();

        const saveRes = await Post('/agency/client/logistics', {
          data: {
            id: record.id || undefined,
            ...res,
            time: `${res.min_time}-${res.max_time}`
          }
        });
        if (saveRes?.code === 2000) {
          editedRef.current = true;
          result = true;
        }
      }
    } catch (error) {
      console.log(error);
    }

    setSubmitLoading(false);

    return result;
  };

  const saveHandler = async () => {
    const isSuccess = await handleSave();
    if (isSuccess) {
      confirmClose();
    }
  };

  const closeHandler = () => {
    if (editedNotSavedRef.current) {
      Modal.confirm({
        title: '是否离开',
        icon: null,
        closable: true,
        // show: true,
        content: '要在关闭之前保存对物流模版的编辑吗？',
        okText: '保存',
        cancelText: '不保存',
        onOk: async () => {
          const saveSuccess = await handleSave();
          if (saveSuccess) {
            confirmClose();
          }
        },
        onCancel: async () => {
          console.log('不保存');
          confirmClose();
        }
      });
    } else {
      confirmClose();
    }
  };

  useEffect(() => {}, []);

  const dropChange = () => {
    onClose && onClose(false);
  };

  const handleOnChange = useCallback(() => {
    editedNotSavedRef.current = true;
  }, []);

  return (
    <Drawer
      title="物流模版配置"
      width={422}
      {...restProps}
      visible={visible}
      onClose={closeHandler}
      closable
      maskClosable
      destroyOnClose
      className={styles.drawer}
    >
      <div className={styles.drawerInner}>
        <EditForm
          record={record}
          ref={formInstance}
          onChange={handleOnChange}
        />
      </div>
      <div className={styles.drawerFooter}>
        <Button onClick={dropChange}>取消</Button>
        <Button loading={submitLoading} onClick={saveHandler} type="primary">
          保存
        </Button>
      </div>
    </Drawer>
  );
};

const ShippingManagement = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const onEditClick = useCallback(record => {
    // 深拷贝
    const copyRecord = JSON.parse(JSON.stringify(record));

    // 字符串转数组
    copyRecord.send_goods_country = record.send_goods_country.split(',');

    setEditingRecord(copyRecord);
    setDrawerVisible(true);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: '物流方案名称',
        dataIndex: 'name',
        key: 'name',
        width: 110,
        render: record => {
          return (
            <Tooltip title={record}>
              <div className={styles.ellipsis} style={{ width: 110 }}>
                {record}
              </div>
            </Tooltip>
          );
        }
        // width: 200
      },
      {
        title: '物流时效(天)',
        dataIndex: 'time',
        key: 'time'
      },
      {
        title: '是否可追踪',
        dataIndex: 'is_trace',
        key: 'is_trace',
        render: record => {
          return record === 2 ? '是' : '否';
        }
        // width: 200
      },
      {
        title: '送达国家',
        dataIndex: 'send_goods_country',
        key: 'send_goods_country'
      },
      {
        title: '重量最小值(g)',
        dataIndex: 'weight_min',
        key: 'weight_min'
      },
      {
        title: '重量最大值(g)',
        dataIndex: 'weight_max',
        key: 'weight_max'
      },
      {
        title: '首重费用($/g)',
        dataIndex: 'first_weight_fee',
        key: 'first_weight_fee'
      },
      {
        title: '续重费用($)',
        dataIndex: 'reweight_fee',
        key: 'reweight_fee'
      },
      {
        title: '挂号费($)',
        dataIndex: 'registration_fee',
        key: 'registration_fee'
      },
      {
        title: '首重(g)',
        dataIndex: 'first_weight',
        key: 'first_weight'
      },
      {
        title: '计费续重(g)',
        dataIndex: 'reweight',
        key: 'reweight'
      },
      {
        title: '系数',
        dataIndex: 'coefficient',
        key: 'coefficient'
      },
      {
        title: '操作',
        colSpan: 2,
        width: 60,
        render: record => {
          return (
            <TextButton onClick={() => onEditClick(record)}>编辑</TextButton>
          );
        }
      },
      {
        colSpan: 0,
        width: 60,
        render: record => {
          return (
            <TextButton danger onClick={() => onDeleteClick(record)}>
              删除
            </TextButton>
          );
        }
      }
    ];
  }, [onEditClick]);

  const fetchList = async () => {
    setLoading(true);

    const res = await Get('/agency/client/logistics/list', {
      data: {
        page: currentPage,
        pageSize: 20
      }
    });

    setList(res?.data?.logisticsList || []);

    setTotalCount(res?.data?.count || 0);

    setLoading(false);
  };

  const onDeleteClick = useCallback(record => {
    Modal.confirm({
      title: '确认删除',
      icon: null,
      content: '是否要删除当前物流配置',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        console.log(record);
        try {
          const res = await Delete('/agency/client/logistics', {
            data: { id: record.id }
          });
        } catch (e) {
          console.log(e);
        }
        fetchList();
      },
      onCancel: async () => {
        console.log(record);
      }
    });
  }, []);

  useEffect(() => {
    fetchList();
  }, [currentPage]);

  const handleCloseDrawer = isEdited => {
    setDrawerVisible(false);
    if (isEdited) {
      fetchList();
    }
  };

  const handlePageChange = e => {
    // console.log(e, 'page changed');
    setCurrentPage(e);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className={styles.card}>
          <div className={styles.tableHeader}>
            <div className={styles.title}>物流价格配置表</div>
            <IconButton
              onClick={() =>
                onEditClick({
                  name: '',
                  time: '20-80',
                  is_trace: 2,
                  send_goods_country: 'GLOBAL',
                  weight_min: '',
                  weight_max: '',
                  first_weight_fee: '',
                  reweight_fee: '',
                  registration_fee: '',
                  first_weight: '',
                  reweight: ''
                })
              }
              // icon={<span className="material-icons">add</span>}
              type="primary"
            >
              <span className="material-icons">add</span>添加物流方案
            </IconButton>
          </div>
          <AgencyManagementTable
            dataSource={list}
            columns={columns}
            pagination={false}
            rowKey={e => {
              return `${e.id}`;
            }}
          />

          <div className={styles.pageContainer}>
            <Pagination
              current={currentPage}
              pageSize={20}
              onChange={handlePageChange}
              hideOnSinglePage
              total={totalCount}
            />
          </div>
        </div>
      </Spin>
      <EidtDrawer
        record={editingRecord}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
      />
    </div>
  );
};

export default ShippingManagement;
