import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import {
  Button,
  Spin,
  Drawer,
  Modal,
  InputNumber,
  Select,
  Form,
  Input,
  Pagination,
  Tooltip,
  Popover,
  Table,
  message
} from 'antd';

const { Option } = Select;

import AgencyManagementTable from './AgencyManagementTable';
import TextButton from './TextButton';
import styles from './Common.less';
import { Get, Post, Put } from '../request';
import { type } from 'os';

// 复制
const copyData = async value => {
  try {
    await navigator.clipboard.writeText(value);
    message.success('复制成功');
  } catch (error) {
    console.log(error);
    message.success('复制失败');
  }
};

// 批量编辑弹框配置文件
const batchConfigurationConfig = [
  {
    name: 'SKU重量',
    Identifying: 'sku_weight',
    type: 'InputNumber',
    step: '1',
    units: 'g',
    precision: 0
  },
  {
    name: 'SKU价格',
    Identifying: 'sku_price',
    type: 'InputNumber',
    step: '0.01',
    units: '$',
    precision: 2
  },
  {
    name: '是否包邮',
    Identifying: 'frank',
    type: 'Select',
    option: [
      { key: 2, value: '是' },
      { key: 1, value: '否' }
    ]
  },
  {
    name: '备注',
    Identifying: 'note',
    type: 'Input'
  }
];

@Form.create()
class EditForm extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props.form);
  }

  handleFreeShippingChange(e) {
    const { setFieldsValue, getFieldValue, validateFields } = this.props.form;
    const weight = getFieldValue('weight');

    setTimeout(() => {
      setFieldsValue({ weight: weight || '' });
      validateFields();
    }, 0);
  }

  selectiveModule(item) {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    switch (item.type) {
      case 'Input':
        return (
          <>{getFieldDecorator(item.Identifying)(<Input width={180} />)}</>
        );

      case 'InputNumber':
        return (
          <>
            {getFieldDecorator(item.Identifying)(
              <InputNumber
                step={item.step}
                min={0}
                width={180}
                precision={item.precision}
              />
            )}
            <span style={{ marginLeft: 6 }}>{item.units}</span>
          </>
        );

      default:
        return getFieldDecorator(item.Identifying, {
          initialValue: 2
        })(
          <Select
            style={{ width: 65 }}
            suffixIcon={<i className="material-icons">arrow_drop_down</i>}
          >
            {item.option.map(ele => {
              return (
                <Option value={ele.key} key={ele.key}>
                  {ele.value}
                </Option>
              );
            })}
          </Select>
        );
    }
  }

  render() {
    const { record, form } = this.props;
    const { getFieldDecorator, getFieldValue, validateFields } = form;

    return (
      <Form name="agency-product-cost">
        <div className={styles.batchDecision}>
          {batchConfigurationConfig.map(item => {
            return (
              <Form.Item title={item.Identifying} key={item.Identifying}>
                <div className={styles.item}>
                  <div className={styles.name}>{item.name}</div>
                  <div className={styles.inputBox}>
                    {this.selectiveModule(item)}
                  </div>
                </div>
              </Form.Item>
            );
          })}
        </div>
      </Form>
    );
  }
}

const EidtModal = ({ record, visible, onClose, ...restProps }) => {
  const editedRef = useRef(false);
  const editedNotSavedRef = useRef(false);
  const formInstance = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  // 批量编辑弹框是否展示
  const [bulkEditStatus, setBulkEditStatus] = useState(false);
  // 批量修改内容
  const [batchList, setBatchList] = useState([]);
  // SKU 列表
  const [skuList, setSkuList] = useState([]);
  // SKU 列表 loading
  const [loading, setLoading] = useState(false);

  // 筛选类型
  const [filterType, setFilterType] = useState('sp_sku');
  // 筛选内容
  const [filterContent, setFilterContent] = useState('');

  const reviseContent = (e, id, type) => {
    skuList.forEach(item => {
      if (id === item.id) {
        item[type] = e ? e : '';
      }
    });
    editedNotSavedRef.current = true;
    setSkuList(JSON.parse(JSON.stringify(skuList)));
  };

  const columns = [
    {
      title: '客户店铺商品Variant',
      dataIndex: 'sp_sku_simple',
      key: 'sp_sku_simple',
      width: 150,
      render: (title, record) => {
        return (
          <div>
            {record.sp_image ? (
              <Popover
                content={<img height="200" width="200" src={record.sp_image} />}
              >
                <img
                  onError={e => {
                    if (e.target) {
                      e.target.setAttribute('style', 'display: none');
                    }
                  }}
                  height="20"
                  width="20"
                  src={record.sp_image}
                />
              </Popover>
            ) : null}
            {title || '--'}
          </div>
        );
      }
    },
    {
      title: '客户店铺商品SKU',
      dataIndex: 'sp_sku',
      key: 'sp_sku',
      width: 150,
      render: record => {
        return <div>{record || '--'}</div>;
      }
    },
    {
      title: '客户店铺商品SKU ID',
      dataIndex: 'variant_id',
      key: 'variant_id',
      width: 150,
      render: record => {
        return <div>{record || '--'}</div>;
      }
    },
    {
      title: '速卖通商品Variant',
      dataIndex: 'ae_sku_simple',
      key: 'ae_sku_simple',
      width: 150,
      render: (title, record) => {
        return (
          <div>
            {record.ae_image ? (
              <Popover
                content={<img height="150" width="150" src={record.ae_image} />}
              >
                <img
                  onError={e => {
                    if (e.target) {
                      e.target.setAttribute('style', 'display: none');
                    }
                  }}
                  height="20"
                  width="20"
                  src={record.ae_image}
                />
              </Popover>
            ) : null}
            {title || '--'}
          </div>
        );
      }
    },
    {
      title: '速卖通商品SKU',
      dataIndex: 'ae_sku',
      key: 'ae_sku',
      width: 150,
      render: record => {
        return <div>{record || '--'}</div>;
      }
    },
    {
      title: 'SKU重量 (单位: g)',
      dataIndex: 'weight',
      key: 'weight',
      width: 120,
      render: (value, record) => (
        <>
          <InputNumber
            step={1}
            precision={0}
            min={0}
            style={{
              widows: 120
            }}
            value={value}
            onChange={e => {
              reviseContent(e ? String(e) : '', record.id, 'weight');
            }}
          />
        </>
      )
    },
    {
      title: 'SKU价格 (单位: $)',
      dataIndex: 'cost',
      key: 'cost',
      width: 120,
      render: (value, record) => (
        <>
          <InputNumber
            step={0.01}
            precision={2}
            min={0}
            style={{
              widows: 120
            }}
            value={value}
            onChange={e => {
              reviseContent(e ? String(e) : '', record.id, 'cost');
            }}
          />
        </>
      )
    },
    {
      title: '是否包邮',
      dataIndex: 'shipping_free',
      key: 'shipping_free',
      width: 120,
      render: (value, record) => (
        <>
          <Select
            style={{
              width: 120
            }}
            value={value}
            onChange={e => {
              reviseContent(e, record.id, 'shipping_free');
            }}
            suffixIcon={<i className="material-icons">arrow_drop_down</i>}
          >
            <Option value={2}>是</Option>
            <Option value={1}>否</Option>
          </Select>
        </>
      )
    },
    {
      title: '备注',
      dataIndex: 'note',
      key: 'note',
      width: 120,
      render: (value, record) => (
        <Input
          style={{
            widows: 120
          }}
          value={value}
          onChange={e => {
            console.log(e.target.value);
            reviseContent(e.target.value, record.id, 'note');
          }}
        />
      )
    }
  ];

  const confirmClose = () => {
    onClose && onClose(editedRef.current);
    editedRef.current = false;
    editedNotSavedRef.current = false;
    setSubmitLoading(false);
  };

  const handleSave = async () => {
    for (let index = 0; index < skuList.length; index++) {
      const element = skuList[index];
      if (
        element.shipping_free === 1 &&
        (element.weight === undefined ||
          element.weight === null ||
          element.weight === 0 ||
          element.weight === '' ||
          element.weight === '0')
      ) {
        message.error('SKU重量不可为0或空');
        return;
      }
    }

    setSubmitLoading(true);
    let result = false;

    try {
      const saveRes = await Put('/agency/client/product', {
        data: skuList
      });

      if (saveRes?.code === 2000) {
        editedRef.current = true;
        result = true;
        editedNotSavedRef.current = false;
        message.success('保存成功');
      }
    } catch (error) {
      console.log(error);
    }

    setSubmitLoading(false);

    return result;
  };

  const saveHandler = async () => {
    const form = formInstance.current;
    if (form) {
      const res = await form.validateFields();

      if (
        res.frank === 1 &&
        (res.sku_weight === undefined ||
          res.sku_weight === 0 ||
          res.sku_weight === '')
      ) {
        message.error('SKU重量不可为0或空');
        return;
      }

      editedNotSavedRef.current = true;
      skuList.forEach((ele, index) => {
        const checked = batchList.includes(index);
        if (checked) {
          ele.weight = res.sku_weight ? String(res.sku_weight) : '';
          ele.cost = res.sku_price ? String(res.sku_price) : '';
          ele.shipping_free = res.frank;
          ele.note = res.note;
        }
      });

      setSkuList(JSON.parse(JSON.stringify(skuList)));
    }
    setBulkEditStatus(false);
  };

  const closeHandler = () => {
    if (editedNotSavedRef.current) {
      Modal.confirm({
        title: '是否离开',
        icon: null,
        closable: true,
        // show: true,
        content: '要在关闭之前保存对商品价格的编辑吗？',
        okText: '保存',
        cancelText: '不保存',
        onOk: async () => {
          const saveSuccess = await handleSave();
          if (saveSuccess) {
            confirmClose();
          }
        },
        onCancel: async () => {
          console.log('不保存');
          setBatchList([]);
          setFilterType('sp_sku');
          setFilterContent('');
          setSkuList([]);
          confirmClose();
        }
      });
    } else {
      setBatchList([]);
      setFilterType('sp_sku');
      setFilterContent('');
      setSkuList([]);
      confirmClose();
    }
  };

  const fetchList = async () => {
    setLoading(true);

    try {
      const res = await Get(
        `/agency/client/product/variant/list?spId=${record.sp_id}&search_type=${filterType}&search_text=${filterContent}`
      );

      if (res.code === 2000) {
        setSkuList(res.data);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchList();
    }
  }, [visible]);

  const dropChange = () => {
    onClose && onClose(editedRef.current);
    editedRef.current = false;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBatchList(selectedRowKeys);
    }
  };

  return (
    <>
      <Drawer
        title="商品价格配置"
        width={1440}
        {...restProps}
        visible={visible}
        onClose={closeHandler}
        closable
        maskClosable
        destroyOnClose
        className={styles.drawer}
      >
        <div className={styles.content}>
          {/* title */}
          <div className={styles.title}>
            <div className={styles.productId}>
              客户使用的平台商品ID:{' '}
              <span>
                <a href={record.ae_url} target="_blank">
                  {record.ae_id}{' '}
                </a>
                <i
                  className="material-icons"
                  style={{
                    fontSize: 24
                  }}
                  onClick={() => copyData(record.sp_id)}
                >
                  copy_all
                </i>
              </span>
            </div>
            <div className={styles.batchButton}>
              <Button
                type="primary"
                onClick={() => setBulkEditStatus(true)}
                disabled={!batchList.length}
              >
                选中并批量编辑
              </Button>
            </div>
          </div>

          <div className={styles.filtrate}>
            <div>
              <Select
                style={{
                  width: 180
                }}
                value={filterType}
                onChange={setFilterType}
              >
                <Option value="sp_sku">客户店铺商品SKU</Option>
                <Option value="variant_id">客户店铺商品SKU ID</Option>
              </Select>
              <Input
                style={{
                  width: 230
                }}
                value={filterContent}
                onChange={e => setFilterContent(e.target.value)}
                placeholder="请输入"
              />
            </div>
            <div className={styles.button}>
              <Button type="primary" onClick={() => fetchList()}>
                查询
              </Button>
            </div>
          </div>

          {/* 表格 */}
          <div className={styles.drawerInner}>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection
              }}
              className={styles.tableStyle}
              pagination={false}
              loading={loading}
              columns={columns}
              align="center"
              dataSource={skuList}
              rowKey={(record, index) => index}
            />
          </div>
          {/* 底部按钮 */}
          <div className={styles.drawerFooter}>
            <Button onClick={dropChange}>取消</Button>
            <Button loading={submitLoading} onClick={handleSave} type="primary">
              保存
            </Button>
          </div>
        </div>
      </Drawer>
      {/* 批量编辑弹框 */}
      <Modal
        title="请为选中的SKU填充/选择下列信息"
        visible={bulkEditStatus}
        onCancel={() => setBulkEditStatus(false)}
        destroyOnClose
        width="480px"
        wrapClassName={styles.modalStyles}
        height="290px"
        footer={null}
      >
        <EditForm
          record={record}
          ref={formInstance}
          setBulkEditStatus={setBulkEditStatus}
        />
        <div className={styles.modalFooter}>
          <Button onClick={() => setBulkEditStatus(false)}>取消</Button>
          <Button loading={submitLoading} onClick={saveHandler} type="primary">
            保存
          </Button>
        </div>
      </Modal>
    </>
  );
};

const CostManagement = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});
  const [list, setList] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [searchShopifyId, setSearchShopifyId] = useState('');
  const [searchStatus, setSearchStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const onEditClick = useCallback(record => {
    setEditingRecord({ ...record });
    setDrawerVisible(true);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: '客户店铺商品标题',
        dataIndex: 'sp_title',
        width: 160,
        render: (value, record) => {
          if (record.sp_url) {
            return (
              <a href={record.sp_url} target="_blank">
                {value}
              </a>
            );
          }

          return <div>{value}</div>;
        }
      },

      {
        title: '客户使用的店铺商品ID',
        width: 154,
        dataIndex: 'sp_id',
        key: 'sp_id',
        render: record => {
          return <>{record}</>;
        }
      },

      {
        title: '客户使用的平台商品ID',
        width: 120,
        dataIndex: 'ae_url',
        key: 'ae_url',
        render: record => {
          return (
            // <Tooltip title={record}>
            <div className={styles.aeid}>
              {record ? (
                <>
                  <TextButton target="_blank" href={record}>
                    {record
                      .match(/\/item\/([0-9]+)\.html/)?.[1]
                      ?.slice(0, 30) || ''}
                  </TextButton>
                  <i
                    className="material-icons"
                    style={{ fontSize: 24 }}
                    onClick={() =>
                      copyData(
                        record
                          .match(/\/item\/([0-9]+)\.html/)?.[1]
                          ?.slice(0, 30) || ''
                      )
                    }
                  >
                    copy_all
                  </i>
                </>
              ) : (
                '--'
              )}
            </div>
            // </Tooltip>
          );
        }
      },

      {
        title: '是否包邮',
        width: 120,
        render: record => {
          switch (record.shipping_status) {
            case 1:
              return <>不包邮</>;
            case 2:
              return <>包邮</>;
            case 3:
              return <>部分包邮</>;
            default:
              break;
          }
        }
      },
      {
        title: '商品价格 (单位: $)',
        width: 80,
        render: record => {
          if (record.max_cost === record.min_cost) {
            return <div className={styles.moneyStyle}>{record.min_cost}</div>;
          }
          return (
            <div className={styles.moneyStyle}>
              {record.min_cost} ~ {record.max_cost}
            </div>
          );
        }
      },
      {
        title: '操作',
        width: 60,
        render: record => {
          return (
            <TextButton onClick={() => onEditClick(record)} className="ds-link">
              编辑
            </TextButton>
          );
        }
      }
    ];
  }, [onEditClick]);

  const fetchList = async (isSearch = false) => {
    setLoading(true);

    try {
      const res = await Get('/agency/client/product/list', {
        data: {
          page: isSearch ? 1 : currentPage,
          pageSize: 20,
          aeId: searchId,
          spId: searchShopifyId,
          isConfig: searchStatus
        }
      });

      setList(res?.data?.prodList || []);

      setTotalCount(res?.data?.count || 0);
    } catch (error) {}

    setLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, [currentPage]);

  const handleSearch = () => {
    if (loading) return;
    try {
      if (currentPage !== 1) {
        setCurrentPage(1);
        return;
      } else {
        fetchList(true);
      }
    } catch (error) {}
  };

  const handleCloseDrawer = isEdited => {
    setDrawerVisible(false);
    if (isEdited) {
      fetchList();
    }
  };

  const handlePageChange = e => {
    setCurrentPage(e);
  };

  const handleInputChange = value => {
    try {
      if (!value?.trim()) {
        setSearchId('');
        return;
      }

      if (
        value.includes('aliexpress.com/item') ||
        value.includes('aliexpress.us/item')
      ) {
        const newValue =
          value.match(/\/item\/([0-9]+)\.html/)?.[1]?.slice(0, 30) || '';
        setSearchId(newValue);
        // Shopify ID 与 AliExpress ID 不能共存 修改AliExpress ID时删除Shopify ID
        setSearchShopifyId('');
        return;
      }

      const newValue = value.replace(/[^0-9]/g, '').slice(0, 30);
      setSearchId(newValue);
      // Shopify ID 与 AliExpress ID 不能共存 修改AliExpress ID时删除Shopify ID
      setSearchShopifyId('');
    } catch (error) {
      console.log(error);
    }
  };

  const handleShopifyInputChange = value => {
    try {
      if (!value?.trim()) {
        setSearchShopifyId('');
        return;
      }

      const newValue = value.replace(/[^0-9]/g, '').slice(0, 30);

      setSearchShopifyId(newValue);
      // Shopify ID 与 AliExpress ID 不能共存 修改Shopify ID时删除AliExpress ID
      setSearchId('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className={styles.card}>
          <div className={styles.tableHeader}>
            <div className={styles.title}>商品价格配置表</div>
          </div>
          <div className={styles.searchBar}>
            <div className={styles.searchBarItem}>
              店铺 ID:
              <Input
                style={{ width: 180, marginLeft: 8 }}
                value={searchShopifyId}
                onChange={e => handleShopifyInputChange(e.target.value)}
                // step={0}
              />
            </div>
            <div className={styles.searchBarItem}>
              平台 ID:
              <Input
                style={{ width: 180, marginLeft: 8 }}
                value={searchId}
                onChange={e => handleInputChange(e.target.value)}
                // step={0}
              />
            </div>
            <div className={styles.searchBarItem}>
              价格是否配置:
              <Select
                onChange={e => setSearchStatus(e)}
                style={{ width: 153, marginLeft: 8 }}
                value={searchStatus}
              >
                <Select.Option value={0}>全部</Select.Option>
                <Select.Option value={1}>已配置</Select.Option>
                <Select.Option value={2}>未配置</Select.Option>
              </Select>
            </div>

            <Button
              style={{ height: 32 }}
              onClick={handleSearch}
              size="default"
              type="primary"
              loading={loading}
            >
              查询
            </Button>
          </div>
          <AgencyManagementTable
            dataSource={list}
            columns={columns}
            pagination={false}
            rowKey={e => {
              return `${e.id}`;
            }}
          />

          <div className={styles.pageContainer}>
            <Pagination
              current={currentPage}
              pageSize={20}
              onChange={handlePageChange}
              hideOnSinglePage
              total={totalCount}
            />
          </div>
        </div>
      </Spin>
      <EidtModal
        record={editingRecord}
        visible={drawerVisible}
        onClose={handleCloseDrawer}
      />
    </div>
  );
};

export default CostManagement;
