import React, { useMemo } from 'react';
import { Table } from 'antd';
import classNames from 'classnames';

import styles from './AgencyManagementTable.less'


const AgencyManagementTable = ({ className, ...restProps }) => {
    const calclulateClassName = useMemo(() => {
        return classNames(styles.table, className);
    }, [className]);
    return <Table size='default' className={calclulateClassName} { ...restProps } bordered pagination={false} />
}

export default AgencyManagementTable;