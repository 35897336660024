import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './TextButton.less';

export default ({ children, className, danger, ...restProps }) => {
  const calcluateClassName = useMemo(() => {
    return classNames(className, styles.textButton, {
      [styles.danger]: danger
    });
  }, [className]);
  return (
    <a {...restProps} className={calcluateClassName}>
      {children}
    </a>
  );
};
