import React, { useEffect, useState } from 'react';
import { Button, Spin, message, Modal } from 'antd';

import styles from './Common.less';
import { Delete, Get, Post } from '../request';
import TextButton from './TextButton';

const AgencyPaymentManagement = ({ userInfo, stripeAuthAccount }) => {
  const [loading, setLoading] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  // const [stripeInfo, setStripeInfo] = useState({});

  // const fetchStripeInfo = async () => {
  //   try {
  //     const res = await Get('/stripe/info');
  //     if (res.code === 2000) {
  //       setStripeInfo(res.data || {});
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onAuth = async () => {
    if (authLoading) return;
    setAuthLoading(true);

    try {
      const authUrlRes = await Post('/agency/client/stripe/auth', {
        data: { action: 1 }
      });
      if (authUrlRes.code === 2000) {
        if (authUrlRes?.data) {
          setModalVisible(true);
          window.open(authUrlRes?.data, 'blank');
        } else {
          message.success('授权成功');
        }
      } else {
        message.error('授权失败');
      }
    } catch (error) {
      console.log(error);
    }
    setAuthLoading(false);
  };

  const onDeleteAuth = async () => {
    if (authLoading) return;
    setAuthLoading(true);
    // setModalVisible(true);
    try {
      const authUrlRes = await Post('/agency/client/stripe/auth', {
        data: { action: 2 }
      });
      if (authUrlRes.code === 2000) {
        message.success('删除授权成功');
        window.location.reload();
      } else {
        message.error('删除授权失败');
      }
    } catch (error) {
      console.log(error);
    }
    setAuthLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      // await fetchStripeInfo();
      setLoading(false);
    })();
  }, []);

  return (
    <Spin spinning={loading}>
      <div className={styles.card}>
        <div className={styles.paymentManagement}>
          <div className={styles.paymentTitle}>收款设置</div>
          <div className={styles.paymentTip}>
            {stripeAuthAccount ? (
              <>
                你的DSers用户（<span>{userInfo?.email}</span>
                ）可向你如下Stripe账号发起付款，如需更换Stripe账号，请解除授权后再次发起授权。
              </>
            ) : (
              <>
                你的DSers用户（<span>{userInfo?.email}</span>
                ）申请使用stripe作为付款方式。
                请点击下方授权按钮，完成你的stripe账号授权以便于您的用户向你发起付款。
              </>
            )}
          </div>
          <div className={styles.stripeInfo}>
            <span>Stripe账号：</span>
            <span className={styles.stripeEmail}>
              {stripeAuthAccount ? stripeAuthAccount : '授权完成后展示'}
            </span>
            <div className={styles.authToStripe}>
              {stripeAuthAccount ? (
                <TextButton onClick={onDeleteAuth} loading={authLoading}>
                  解除授权
                </TextButton>
              ) : null}
            </div>
          </div>
          <div style={{ marginTop: 16 }}>
            <Button
              loading={authLoading}
              disabled={!!stripeAuthAccount}
              onClick={onAuth}
              type="primary"
            >
              {stripeAuthAccount ? '已授权' : '授权'}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        closable={false}
        maskClosable={false}
        visible={modalVisible}
        footer={null}
        width={580}
        title="温馨提示"
        centered
      >
        <div>授权完成后请刷新此页面以获取您的最新信息</div>
        <div style={{ paddingBottom: 16, paddingTop: 16, textAlign: 'right' }}>
          <Button type="primary" onClick={() => window.location.reload()}>
            刷新页面
          </Button>
        </div>
      </Modal>
    </Spin>
  );
};

export default AgencyPaymentManagement;
