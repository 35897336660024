import React, { useState, useEffect, useMemo } from 'react';
import { Form, Layout, Tabs, Button, Spin, Input, message } from 'antd';
import classNames from 'classnames';
import { parse } from 'qs';

import styles from './index.less';
import DSersAlert from 'routes/AgencyOrder/component/DSersAlert';
import {
  getAccessPass,
  getAgencyUserKey,
  removeAccessPass,
  setAccessPass
} from './utils';
import CostManagement from './components/CostManagement';
import AgencyManagementTable from './components/AgencyManagementTable';
import AgencyPaymentManagement from './components/AgencyPaymentManagement';
import ShippingManagement from './components/ShippingManagement';
import { Get, Post } from './request';

const ShippingCostTemplateTable = () => {
  const columns = [
    { title: '重量区间最小值(g)', key: '1', dataIndex: 'weight_min' },
    { title: '重量区间最大值(g)', key: '2', dataIndex: 'weight_max' },
    { title: '首重费用($/g)', key: '3', dataIndex: 'init_cost' },
    { title: '续重费用($)', key: '4', dataIndex: 'over_init_cost' },
    { title: '挂号费($)', key: '5', dataIndex: 'operate_fee' },
    { title: '首重(g)', key: '6', dataIndex: 'init_weight' },
    { title: '计费续重(g)', key: '7', dataIndex: 'over_init_weight' },
    { title: '系数', key: '8', dataIndex: 'over_init_coefficient' }
  ];
  return (
    <AgencyManagementTable
      dataSource={[
        {
          key: '1',
          weight_min: '0',
          weight_max: '150',
          init_cost: '0.075',
          over_init_cost: '	0.075',
          operate_fee: '20',
          init_weight: '0',
          over_init_weight: '1',
          over_init_coefficient: '*1'
        }
      ]}
      columns={columns}
      className={styles.templateTable}
    />
  );
};

const AgencyCostManagement = ({ dispatch }) => {
  // 是否登录
  const [loginStatus, setLoginStatus] = useState(false); // -------------
  // 初始化loading
  const [loading, setLoading] = useState(true);
  // 登录loading
  const [loginLoading, setLoginLoading] = useState(false);
  // 输入框password
  const [password, setPassword] = useState('');

  const [managementMeta, setManagementMeta] = useState({});

  const [stripeAuthAccount, setStripeAuthAccount] = useState('');

  const [alertVisible, setAlertVisible] = useState(true);

  const [invalidKey, setInvalidKey] = useState(false);

  const getAgencyUserMeta = async () => {
    const key = getAgencyUserKey();
    if (!key) {
      return;
    }
    try {
      const res = await Get('/agency/client/user/info', { data: { key: key } });
      if (res && res.code === 2000) {
        return res.data;
      }
      // return { email: '1282828@qq.com', userName: 'Test User' };
    } catch (error) {
      // return { email: '1282828@qq.com', userName: 'Test User' };
      console.log(error);
    }
  };

  const validatePass = async password => {
    if (!password) {
      message.error('登录失败，请检查访问密码是否正确');
      return;
    }
    setLoginLoading(true);
    const key = getAgencyUserKey();

    try {
      // const res = await new Promise(resolve => {
      //   setTimeout(() => {
      //     resolve(
      //       password === '1234'
      //         ? {
      //             login: true,
      //             name: 'Test User',
      //             email: 'testwixaccount@dsers.com'
      //           }
      //         : { login: false }
      //     );
      //   }, 1000);
      // });

      const res = await Post('/agency/client/login', {
        data: { password, key }
      });

      if (res.code === 2000 && res?.data?.token) {
        setAccessPass(res?.data?.token);
        if (managementMeta.stripe_contact) {
          try {
            const stripeAuthStatus = await Get('/agency/client/stripe/status');
            if (stripeAuthStatus.code === 2000) {
              setStripeAuthAccount(stripeAuthStatus.data);
            }
          } catch (error) {}
        }
        setLoginStatus(true);

        // setManagementMeta(res);
        // alert(res?.data?.token);

        setPassword('');
      } else {
        // setLoginStatus(true);
        // // setManagementMeta(res);
        // setAccessPass(res?.data || 'fdsafdsafdsafdsa');
        // setPassword('');
        message.error('登录失败，请检查访问密码是否正确');
      }
    } catch (error) {
      message.error('登录失败，请检查访问密码是否正确');
    }
    setLoginLoading(false);
  };

  useEffect(() => {
    (async () => {
      const userMeta = await getAgencyUserMeta();

      if (!userMeta) {
        setInvalidKey(true);
        setLoading(false);
        return;
      } else {
        setManagementMeta(userMeta);
      }

      const localPass = getAccessPass();
      if (!!localPass) {
        if (userMeta.stripe_contact) {
          try {
            const stripeAuthStatus = await Get('/agency/client/stripe/status');
            if (stripeAuthStatus.code === 2000) {
              setStripeAuthAccount(stripeAuthStatus.data);
            }
          } catch (error) {}
        }
        setLoginStatus(true);
      }
      setLoading(false);
    })();
  }, []);

  const logout = () => {
    removeAccessPass();
    setLoginStatus(false);
  };

  useEffect(() => {}, []);

  const userTitle = useMemo(() => {
    if (!managementMeta) return '';
    return `${managementMeta.user_name}(${managementMeta.email})`;
  }, [managementMeta]);

  if (loading) {
    return (
      <Spin
        spinning={loading}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      ></Spin>
    );
  }

  // if (invalidKey) {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         height: '100%',
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         fontSize: 32,
  //         fontWeight: 'bold'
  //       }}
  //     >
  //       Agency管理页面URL有误，请查看您邮箱点击正确的链接以访问此内容
  //     </div>
  //   );
  // }

  return (
    <Layout className={classNames({ [styles.notLogin]: !loginStatus })}>
      <Layout.Header className={styles.top}>
        <a className={styles.logo} target="_blank" href="/">
          <img
            src={
              loginStatus
                ? 'https://img.dsers.com/icon/logo-primary.svg'
                : 'https://img.dsers.com/images/logo.png'
            }
          />
        </a>
        {loginStatus ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <div style={{ marginRight: 10 }}>{userTitle}</div> */}
            <Button type="primary" onClick={logout} className={styles.logout}>
              退出
            </Button>
          </div>
        ) : null}
      </Layout.Header>
      <Layout.Content className={styles.content}>
        <div className={styles.contentContainer}>
          {loginStatus ? (
            <div>
              <div className={styles.userTitle}>价格维护表-{userTitle}</div>

              <DSersAlert
                visible={true}
                type="warning"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: 'white'
                }}
                className={styles.alert}
                description={
                  <div
                    style={{
                      width: '100%',
                      position: 'relative',
                      color: 'black'
                    }}
                  >
                    <div></div>
                    {alertVisible ? (
                      <>
                        <b>1.本表作用</b>
                        <div>
                          在您完成将ERP与DSers账户关联后，可通过本表配置客户的采购价和物流费用。配置完成后，您与客户就不再需要通过复杂的CSV进行采购、报价等工作。DSers将根据
                          <p>
                            您的设置自动计算客户应支付的金额，您的用户在DSers可以直接向您的ERP下单。
                          </p>
                        </div>
                        <div style={{ marginTop: 16 }}>
                          <b>2.注意事项</b>
                        </div>
                        <div>
                          <p>
                            1.
                            默认SKU均包邮，若希望该SKU应用“物流价格配置表”中的配置，请将“是否包邮”字段选择“否”。
                          </p>
                          <p>
                            2.
                            您所设置的“物流供应商名称”将会同步到您客户的订单管理后台中，请确保物流名称易于客户理解。
                          </p>
                          <p>
                            3.当您计算出物流价格后，如果您有利润率或者想要预防可能发生的损失的需求，可以设置一个系数。在完成物流费用计算后，将使用该系数进行调整，以确保您的利润。
                          </p>
                        </div>
                        <div style={{ marginTop: 16 }}>
                          <b>3.物流价格计算公式说明</b>
                        </div>
                        <div>
                        公式：（(首重费用 * 首重) ＋(((包裹重量 - 首重) / 计费续重) * 续重费用)＋挂号费）＋（或 *） 系数
                        </div>
                        <div style={{ marginTop: 8 }}>
                          案例:假设发往X国的物流价格配置信息为:
                        </div>
                        <ShippingCostTemplateTable />
                        <div>则一个发往该国家的重量为60g的快递的物流费用为:</div>
                        <div style={{ marginTop: 4 }}>
                        （0*0.075$ + ((60-0) / 1 )*0.075$ + 20$ = 4.5$ + 20$）*1 = 24.5$
                        </div>
                      </>
                    ) : null}
                    <div
                      className={classNames(styles.alertClose, {
                        [styles.closed]: !alertVisible
                      })}
                    >
                      <span
                        onClick={() => setAlertVisible(!alertVisible)}
                        className="material-icons"
                      >
                        expand_more
                      </span>
                    </div>
                  </div>
                }
              />
              <Tabs defaultActiveKey="product-cost" destroyInactiveTabPane>
                <Tabs.TabPane tab="商品成本管理" key="product-cost">
                  <div className={styles.tabPaneContainer}>
                    <CostManagement />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="物流模版管理"
                  key="shipping-method-management"
                >
                  <div className={styles.tabPaneContainer}>
                    <ShippingManagement />
                  </div>
                </Tabs.TabPane>

                {managementMeta.stripe_contact ? (
                  <Tabs.TabPane
                    tab={
                      <span className={styles.tabText}>
                        收款设置
                        {!stripeAuthAccount ? (
                          <span className={styles.dot} />
                        ) : null}
                      </span>
                    }
                    key="payment-method-management"
                  >
                    <div className={styles.tabPaneContainer}>
                      <AgencyPaymentManagement
                        stripeAuthAccount={stripeAuthAccount}
                        userInfo={managementMeta}
                      />
                    </div>
                  </Tabs.TabPane>
                ) : null}
              </Tabs>
            </div>
          ) : (
            <div className={styles.loginContainer}>
              <img
                width="650"
                src="https://img.dsers.com/shopify/order/agency_cost_manage_login_post.png"
              />
              <div className={styles.loginBox}>
                <div className={styles.title} title={`价格维护表-${userTitle}`}>
                  价格维护表-{userTitle}
                </div>
                <div className={styles.inputTip}>请输入访问密码</div>
                <Input.Password
                  onChange={e => setPassword(e.target.value)}
                  placeholder="请输入访问密码"
                  size="large"
                  prefix={<span className="material-icons">lock</span>}
                />
                <Button
                  loading={loginLoading}
                  onClick={() => validatePass(password)}
                  type="primary"
                >
                  提交
                </Button>
              </div>
            </div>
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default AgencyCostManagement;
