import { parse } from 'qs';

export const storageKey = 'DSERS_AGENCY_COST_MANAGEMENT_PASS';

export const getAgencyUserKey = () =>
  parse(location.search.slice(1))?.key || '';

export const getAccessPass = () => {
  return `${localStorage.getItem(storageKey + '_' + getAgencyUserKey()) || ''}`;
};

export const setAccessPass = pass => {
  localStorage.setItem(storageKey + '_' + getAgencyUserKey(), pass);
};

export const removeAccessPass = () => {
  localStorage.removeItem(storageKey + '_' + getAgencyUserKey());
};
